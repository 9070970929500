define('alert', [], function () {
	return alert;
});
define('colors', [], function () {
	
	var getLuma = function (hex) {
		if (hex.startsWith('#'))
			hex = c.substring(1);
		var rgb = parseInt(hex, 16);   // convert rrggbb to decimal
		var r = (rgb >> 16) & 0xff;  // extract red
		var g = (rgb >> 8) & 0xff;  // extract green
		var b = (rgb >> 0) & 0xff;  // extract blue

		return 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709
	};

	return {
		getLuma : getLuma
	};
});
define('confirm', [], function () {
	return confirm;
});
define('console', [], function () {
	var log = function (message) {
		try {
			console.log(message);
		}
		catch (ex) { }
	};

	return {
		log: log
	}
});
// copied from the github gist at https://gist.github.com/jpiccari/c09ec8a83a23a252e933 written by jpiccari
define(
	'dateFormat',
	function () {
		var DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Firday', 'Saturday'],
			MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
			MONTHS_ABBREV = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
			datePrototype = Date.prototype,
			formatCharacters = {
				// DAY
				// Numeric representation (with leading zero)
				d: function () {
					return padWithZero(applyFormat(this, 'j'));
				},
				// Numeric representation
				j: datePrototype.getDate,
				// Textual representation of day of the week (abbreviated)
				D: function () {
					return MONTHS_ABBREV[applyFormat(this, 'j')];
				},
				// Full textual representation of day of the week
				l: function () {
					return DAYS[applyFormat(this, 'j')];
				},


				// MONTH
				// Full textual representation
				F: function () {
					return MONTHS[applyFormat(this, 'M')];
				},
				// Numberic representation (with leading zero)
				m: function () {
					return padWithZero(applyFormat(this, 'M'));
				},
				// Numberic reprsentation
				M: datePrototype.getMonth,


				// YEAR
				// Full numeric representation
				Y: datePrototype.getFullYear,
				// Two digit representation
				y: function () {
					return applyFormat(this, 'Y').substr(-2);
				},


				// TIME
				// Lowercase Ante meridiem and Post meridiem
				a: function () {
					return applyFormat(this, 'G') > 11 ? 'am' : 'pm';
				},
				// Uppercase Ante meridiem and Post meridiem
				A: function () {
					return applyFormat(this, 'a').toUpperCase();
				},

				g: function () {
					var hours = applyFormat(this, 'G');
					return hours - (hours > 12 && 12);
				},
				G: datePrototype.getHours,
				h: function () {
					return padWithZero(applyFormat(this, 'g'));
				},
				H: function () {
					return padWithZero(applyFormat(this, 'G'));
				},

				i: datePrototype.getMinutes,
				s: datePrototype.getSeconds
			},
			REPLACEMENT_REGEX = new RegExp(Object.keys(formatCharacters).join('|'), 'g');

		/**
		 * Applys a given format given the date context
		 * @param {object} context - The date object to format
		 * @param {string} name - Name of the formatter to used
		 * @returns {string} The formated date property
		 */
		function applyFormat(context, name) {
			return '' + formatCharacters[name].call(context);
		}

		/**
		 * If number is less than 10 prefixes number with a zero
		 * @param {string} str - The number to pad
		 * @returns {string} The resulting string
		 */
		function padWithZero(str) {
			return (str < 10 && '0') + +str;
		}


		/**
		 * Formats a date using the given format string
		 * @param {object} date - Date object to format
		 * @param {string} format - Format string
		 * @returns {string} Formated date string
		 */
		return function (date, format) {
			if (!(date instanceof Date)) {
				throw new TypeError('Expected Date object.');
			}

			return format.replace(REPLACEMENT_REGEX, function (fn) {
				return applyFormat(date, fn);
			});
		};
	}
);
define('dialog', ['jquery'], function ($) {

	var startingSettings = {};

	var defaultSettings = {
		width: 600,
		height: 600,
		maxWidth: 10000,
		maxHeight: 10000,
		modal: true,
		draggable: false,
		resizable: false,
		dialogClass: 'modal-dialog',
		close: dialogClosed
	};

	var $activeDialog = -1;

	var openDialog = function ($content, settings) {
		startingSettings = $.extend({}, defaultSettings, settings);
		if ($activeDialog != -1) {
			$activeDialog.dialog('destroy');
			$activeDialog = -1;
		}
		var dialogSettings = $.extend({}, defaultSettings, settings);
		if (isNaN(dialogSettings.width) && (dialogSettings.width.indexOf('%') >= 0)) {
			var percentWidth = parseInt(dialogSettings.width.replace('%', '')) / 100;
			var widthValue = $(window).width();
			dialogSettings.width = widthValue * percentWidth;
		}
		if (isNaN(dialogSettings.height) && (dialogSettings.height.indexOf('%') >= -1)) {
			var percentHeight = parseInt(dialogSettings.height.replace('%', '')) / 100;
			var heightValue = $(window).height();
			dialogSettings.height = heightValue * percentHeight;
		}
		if (dialogSettings.width > dialogSettings.maxWidth)
			dialogSettings.width = dialogSettings.maxWidth;
		if (dialogSettings.height > dialogSettings.maxHeight)
			dialogSettings.height = dialogSettings.maxHeight;
		var $container = $('<div>');
		$container.append($content);
		$activeDialog = $container.dialog(dialogSettings);
		var $dialogParent = $activeDialog.parents('.ui-dialog');
		$dialogParent.css('z-index', '1000001');
		$dialogParent.find('.ui-dialog-titlebar-close').click(closeDialog);
		$dialogParent.parent().find('.ui-widget-overlay').css('z-index', '1000000');
	};

	var windowResized = function () {
		if ($activeDialog === -1)
			return;

		var newWidth = startingSettings.width;
		var newHeight = startingSettings.height;

		if (isNaN(startingSettings.width) && (startingSettings.width.indexOf('%') >= 0)) {
			var percentWidth = parseInt(startingSettings.width.replace('%', '')) / 100;
			var widthValue = $(window).width();
			newWidth = widthValue * percentWidth;
		}
		if (isNaN(startingSettings.height) && (startingSettings.height.indexOf('%') >= -1)) {
			var percentHeight = parseInt(startingSettings.height.replace('%', '')) / 100;
			var heightValue = $(window).height();
			newHeight = heightValue * percentHeight;
		}
		if (newWidth > startingSettings.maxWidth)
			newWidth = startingSettings.maxWidth;
		if (newHeight > startingSettings.maxHeight)
			newHeight = startingSettings.maxHeight;

		$activeDialog.dialog('option', 'width', newWidth);
		$activeDialog.dialog('option', 'height', newHeight);
	};

	var dialogClosed = function () {
		$activeDialog.dialog('destroy');
		$activeDialog = -1;
	};

	var closeDialog = function () {
		$activeDialog.dialog('destroy');
		$activeDialog = -1;
	};

	var extracts = {
		openDialog: openDialog,
		closeDialog: closeDialog
	};

	$(window).resize(windowResized);

	return extracts;
});
define('floatingAlert', ['jquery', 'objectExtend'], function ($, objectExtend) {

	var alertVisible = false;

	var defaultSettings = {
		severity: 'alert-danger',
		dismissable: true,
		duration: 5000,
		removalDelay: 5000
	};

	var showAlert = function (message, settings) {
		if (alertVisible)
			return;
		if (!settings)
			settings = {};
		var alertSettings = objectExtend(defaultSettings, settings);
		var $body = $('body');
		var $alert = $('<div>').addClass('floating-alert alert ' + alertSettings.severity).html(message);
		if (alertSettings.dismissable)
			$alert.addClass('alert-dismissable');
		$body.append($alert);
        alertVisible = true;
        $('.overlay').removeClass('hide');
        $alert.addClass('visible');
    };


    //$('.floating-alert-confirm-btn').click(function () {
       
    //});

    $(document).on('click', '.floating-alert-confirm-btn', function () {
        $('.floating-alert').remove();
        $('.overlay').addClass('hide');
        alertVisible = false;
       
    });

	return {
		showAlert: showAlert
	}
});
define('hashData', ['location'], function (location) {
	'use strict';

	var delimiter = '/';

	var getHashValue = function (name) {
		name = name.toLowerCase();
		var currentHash = getHash();
		if (currentHash.substr(0, delimiter.length) == delimiter)
			currentHash = currentHash.substr(delimiter.length, currentHash.length - delimiter.length);
		var currentHashArray = currentHash.split(delimiter);
		var newHash = '';
		for (var i = 0; i < currentHashArray.length; i = i + 2) {
			if ((i + 1) >= currentHashArray.length)
				break;
			var thisName = currentHashArray[i];
			var thisValue = currentHashArray[i + 1];
			if (name == thisName)
				return thisValue;
		}
		return null;
	};

	var setHashValue = function (name, value) {
		name = name.toLowerCase();
		var currentHash = getHash();
		if (currentHash.substr(0, delimiter.length) == delimiter)
			currentHash = currentHash.substr(delimiter.length, currentHash.length - delimiter.length);
		var currentHashArray = currentHash.split(delimiter);
		var newHash = '';
		for (var i = 0; i < currentHashArray.length; i = i + 2) {
			if ((i + 1) >= currentHashArray.length)
				break;
			var thisName = currentHashArray[i];
			var thisValue = currentHashArray[i + 1];
			if (name == thisName)
				return thisValue;
		}
		return null;
	};

	var removeHashValue = function (name) {
		name = name.toLowerCase();
		var currentHash = getHash();
		if (currentHash.substr(0, delimiter.length) == delimiter)
			currentHash = currentHash.substr(delimiter.length, currentHash.length - delimiter.length);
		var currentHashArray = currentHash.split(delimiter);
		var newHash = '';
		for (var i = 0; i < currentHashArray.length; i = i + 2) {
			if ((i + 1) >= currentHashArray.length)
				break;
			var thisName = currentHashArray[i];
			var thisValue = currentHashArray[i + 1];
			if (name != thisName)
				newHash += delimiter + thisName + delimiter + thisValue;
		}
		setHash(newHash);
	};

	var getHash = function () {
		return location.hash.replace('#', '');
	};

	var setHash = function (newHash) {
		location.hash = newHash;
	};

	return {
		getHashValue: getHashValue,
		setHashValue: setHashValue,
		removeHashValue: removeHashValue,
		getHash: getHash,
		setHash: setHash
	};
});
define('hotspotTemplateDialog', ['pubsub', 'iframeDialog'], function (bus, iframeDialog) {
	'use strict';

	var updatedCallback = null;

	var init = function () {
		bus.subscribe('selectedtemplates.updated', selectedTemplatesUpdated);
		bus.subscribe('selectedtemplates.close', closeDialog);
	};

	var openDialog = function (clientID, templateIDs, categoryIDs, callback) {
		updatedCallback = callback;
		var url = '/Admin/PerspectiveHotspot/TemplateSelector?ClientID=' + clientID;
		if (categoryIDs != '')
			url += '&categoryIDs=' + categoryIDs;
		if (templateIDs != '')
			url += '&templateIDs=' + templateIDs;
		iframeDialog.openDialog(url, { width: '80%', height: '90%' });
	};

	var selectedTemplatesUpdated = function (updates) {
		updatedCallback(updates);
		closeDialog();
	};

	var closeDialog = function () {
		iframeDialog.closeDialog();
	};

	init();

	return {
		openDialog: openDialog
	};
});
define('hotspotTemplateSortDialog', ['pubsub', 'iframeDialog'], function (bus, iframeDialog) {
	'use strict';

	var updatedCallback = null;

	var init = function () {
		bus.subscribe('sortedtemplates.updated', sortedTemplatesUpdated);
		bus.subscribe('sortedtemplates.close', closeDialog);
	};

	var openDialog = function (clientID, templateIDs, categoryIDs, order, callback) {
		updatedCallback = callback;
		var url = '/Admin/PerspectiveHotspot/TemplateOrder?ClientID=' + clientID;
		if (categoryIDs != '')
			url += '&categoryIDs=' + categoryIDs;
		if (templateIDs != '')
			url += '&templateIDs=' + templateIDs;
		if (order != '')
			url += '&order=' + order;
		iframeDialog.openDialog(url, { width: '80%', height: '90%', title: 'Drag Templates around to Desired Order' });
	};

	var sortedTemplatesUpdated = function (updates) {
		updatedCallback(updates);
		closeDialog();
	};

	var closeDialog = function () {
		iframeDialog.closeDialog();
	};

	init();

	return {
		openDialog: openDialog
	};
});
// progress updates courtesy of http://www.dave-bond.com/blog/2010/01/JQuery-ajax-progress-HMTL5/
define('http', ['jquery', 'windowProgressBar', 'httpErrorHandler'], function ($, windowProgressBar, httpErrorHandler) {
	'use strict';

	var progressBarsEnabled = false;

	var get = function (url, data) {
		return performRequest(url, data, 'GET');
	};
	var post = function (url, data) {
		return performRequest(url, data, 'POST');
	};

	var performRequest = function (url, data, method) {
		var settings = {
			type: method,
			xhr: function () {
				var xhr = new window.XMLHttpRequest();
				xhr.upload.addEventListener("progress", uploadProgressHandler, false);
				xhr.addEventListener("progress", downloadProgressHandler, false);
				xhr.addEventListener("loadend", requestEndHandler, false);
				return xhr;
			}
		};
		if (data)
			settings.data = data;
		return $.ajax(url, settings);
	};

	var uploadProgressHandler = function (event) {
		if (!progressBarsEnabled)
			return;
		if (event.lengthComputable) {
			var percentComplete = (event.loaded / event.total) * .1;
			windowProgressBar.setProgress(percentComplete);
		}
	};

	var downloadProgressHandler = function (event) {
		if (!progressBarsEnabled)
			return;
		if (event.lengthComputable) {
			var percentComplete = 101 + ((event.loaded / event.total) * .9);
			windowProgressBar.setProgress(percentComplete);
		}
	};

	var requestEndHandler = function () {
		if (!progressBarsEnabled)
			return;
		windowProgressBar.removeProgressBar();
	};

	var toggleProgressBars = function (value) {
		progressBarsEnabled = value;
	};

	return {
		get: get,
		post: post,
		toggleProgressBars: toggleProgressBars
	};
});
define('httpErrorHandler', ['floatingAlert'], function (floatingAlert) {

	var errorHandlerFactory = function (settings) {
		return function (event) {
			floatingAlert.showAlert('error handled: ' + event);
		};
	};

	return {
		errorHandlerFactory: errorHandlerFactory
	};
});
define('iframeDialog', ['jquery', 'dialog'], function ($, dialog) {

	var startingSettings = {};

	var defaultSettings = {
		postData: {},
		post: false,
		dialogClass: 'iframe-dialog modal-dialog'
	};

	var openDialog = function (url, settings) {
		startingSettings = $.extend({}, defaultSettings, settings);
		var $iframe;
		if (startingSettings.post) {
			$iframe = $('<iframe>').attr('name', 'dialog-iframe');
			dialog.openDialog($iframe, startingSettings);
			var $form = $('<form>').attr('target', 'dialog-iframe').attr('method', 'post').attr('action', url);
			for (var property in startingSettings.postData) {
				$form.append($('<input>').attr('type', 'hidden').attr('name', property).val(startingSettings.postData[property]));
			}
			$('body').append($form);
			$form.submit().remove();
		}
		else {
			$iframe = $('<iframe>').attr('src', url);
			dialog.openDialog($iframe, startingSettings);
		}
	};

	var extracts = {
		openDialog: openDialog,
		closeDialog: dialog.closeDialog
	};

	return extracts;
});

define('localStorage', ['window', 'log'], function (window, log) {
	'use strict';

	var getItem = function (key) {
		log.addEntry('window.localStorage.getItem(\'' + key + '\');');
		if (window.localStorage)
			return window.localStorage.getItem(key);	
		return null;
	};
	var setItem = function (key, value) {
		log.addEntry('window.localStorage.setItem(\'' + key + '\',\'' + value + '\');');
		if (window.localStorage)
			window.localStorage.setItem(key, value);
	};
	var removeItem = function (key) {
		log.addEntry('window.localStorage.removeItem(\'' + key + '\');');
		if (window.localStorage)
			window.localStorage.removeItem(key);
	};

	return {
		getItem: getItem,
		setItem: setItem,
		removeItem: removeItem
	};
});
define('location', [], function () {
	return {
		hash: window.location.hash,
		host: window.location.host,
		hostname: window.location.hostname,
		href: window.location.href,
		pathname: window.location.pathname,
		port: window.location.port,
		protocol: window.location.protocol,
		search: window.location.search,
		reload: window.location.reload
	};
});
define('log', [], function () {
	'use strict';

	var addEntry = function (entry) {
		if (window.console)
			window.console.log(entry);
	};

	return {
		addEntry: addEntry
	};
});
define('messagingFactory', ['pubsub'], function (bus) {
	'use strict';

	var createChannel = function (channel) {
		return {
			subscribe: function (handler) {
				try
				{
					if (console)
						console.log('Subscribing to channel "' + channel + '"');
				}
				catch (ex)
				{}
				return bus.subscribe(channel, handler);
			},
			publish: function (data) {
				try
				{
					if (console)
						console.log('Publishing to channel "' + channel + '"', data);
				}
				catch (ex)
				{}
				bus.publish(channel, data);
			}
		};
	};

	var extracts = {
		createChannel: createChannel
	};

	return extracts;
});
define('objectExtend', [], function () {

	var extend = function () {

		// Variables
		var extended = {};
		var deep = false;
		var i = 0;
		var length = arguments.length;

		// Check if a deep merge
		if (Object.prototype.toString.call(arguments[0]) === '[object Boolean]') {
			deep = arguments[0];
			i++;
		}

		// Merge the object into the extended object
		var merge = function (obj) {
			for (var prop in obj) {
				if (Object.prototype.hasOwnProperty.call(obj, prop)) {
					// If deep merge and property is an object, merge properties
					if (deep && Object.prototype.toString.call(obj[prop]) === '[object Object]') {
						extended[prop] = extend(true, extended[prop], obj[prop]);
					} else {
						extended[prop] = obj[prop];
					}
				}
			}
		};

		// Loop through each object and conduct a merge
		for (; i < length; i++) {
			var obj = arguments[i];
			merge(obj);
		}

		return extended;

	};

	return extend;
});
define('paginationCalculator', ['utils'], function (utils) {
	'use strict';

	var defaults = {
		viewAllLinkEnabled: false,
		previousLinkEnabled: true,
		nextLinkEnabled: true,
		individualPagesEnabled: true,
		showIndividualPageSpan: 5,
		previousLabel: 'prev',
		nextLabel: 'next'
	};

	var generatePaginationLinksArray = function (itemCount, limit, offset, options) {
		var settings = utils.extend({}, defaults, options);
		var links = [];

		var currentPage = Math.floor(offset / limit) + 1;
		var startPage = utils.boundedNumber(currentPage - settings.showIndividualPageSpan, 1, 99999);
		var lastPage = currentPage + settings.showIndividualPageSpan;
		if ((lastPage * limit) > itemCount)
			lastPage = Math.ceil(itemCount / limit);

		if (settings.viewAllLinkEnabled)
			links.push({ cssClass: 'view-all', html: 'View All (' + itemCount + ')', linkOffset: -1 });

		if (settings.previousLinkEnabled) {
			if (offset > 0)
				links.push({ cssClass: 'previous', html: settings.previousLabel, linkOffset: utils.boundedNumber(offset - limit, 0, 99999) });
			else
				links.push({ cssClass: 'previous disabled', html: settings.previousLabel, linkOffset: -1 });
		}

		if (settings.individualPagesEnabled) {
			for (var i = startPage; i <= lastPage; i++) {
				if (i == currentPage)
					links.push({ cssClass: 'page disabled', html: i, linkOffset: -1 });
				else
					links.push({ cssClass: 'page', html: i, linkOffset: utils.boundedNumber((i - 1) * limit, 0, 99999) });
			}
		}

		if (settings.nextLinkEnabled) {
			if ((offset + limit) > itemCount)
				links.push({ cssClass: 'next disabled', html: settings.nextLabel, linkOffset: -1 });
			else
				links.push({ cssClass: 'next', html: settings.nextLabel, linkOffset: utils.boundedNumber(offset + limit, 0, 99999) });
		}

		return links;
	};

	return {
		generatePaginationLinksArray: generatePaginationLinksArray
	};
});
define('queryString', ['location', 'utils'], function (location, utils) {
	'use strict';
	
	var extensions = ['.aspx', '.html', '.htm', '.cfm'];
	var vars;

	var getItem = function (name) {
		parseQueryString();
		return vars[name.toLowerCase()];
	};

	var parseQueryString = function () {

		if (vars)
			return vars;
		
		vars = {};

		// easy part first
		var query = location.search.substring(1);
		var varArray = query.split('&');
		for (var i = 0; i < varArray.length; i++) {
			var pair = varArray[i].split('=');
			if (pair.length == 2)
				vars[decodeURIComponent(pair[0]).toLowerCase()] = decodeURIComponent(pair[1]);
		}

		// now parse the seo query string
		var pathname = location.pathname;
		if (utils.left(pathname, 1) == '/')
			pathname = pathname.substring(1);
		if (utils.right(pathname, 1) == '/')
			pathname = utils.left(pathname, pathname.length - 1);
		var pathnameArray = pathname.split('/');
		var pathnameFileIndex = -1;
		for (var i = 0; i < pathnameArray.length; i++)
		{
			for (var j = 0; j < extensions.length; j++)
			{
				if (pathnameArray[i].indexOf(extensions[j]) != -1)
				{
					pathnameFileIndex = i;
					break;
				}
			}
			if (pathnameFileIndex != -1)
				break;
		}
		if ((pathnameFileIndex != -1) && (pathnameFileIndex < (pathnameArray.length + 1)))
		{
			for (var i = pathnameFileIndex + 1; (i + 1) < pathnameArray.length; i = i + 2)
			{
				var key = decodeURIComponent(pathnameArray[i]).toLowerCase();
				var value = decodeURIComponent(pathnameArray[i + 1]);
				vars[key] = value;
			}
		}
	};

	return {
		getItem: getItem
	};
});
define('random', [], function () {

	var next = function () {
		if (window.crypto)
			return window.crypto.getRandomValues(new Uint8Array(1))[0];
	};

	var getRandomValues = function (array) {
		if (!array)
			return;
		else if (array.length == 0)
			return;

		if (window.crypto)
			window.crypto.getRandomValues(array);
		else {
			for (var i = 0; i < array.length; i++) {
				array[i] = next();
			}
		}
	};

	return {
		next: next,
		getValues: getValues
	};
});
define('utils', [], function () {
	'use strict';

	var boundedNumber = function (number, minimum, maximum) {
		if (number < minimum)
			return minimum;
		if (number > maximum)
			return maximum;
		return number;
	};

	var right = function (string, length) {
		if (length > string.length)
			throw 'requested length exceeds string length';
		return string.substr(string.length - length);
	};

	var left = function (string, length) {
		if (length > string.length)
			throw 'requested length exceeds string length';
		return string.substr(0, length);
	};

	var extend = function () {
		if (arguments.length == 0)
			return {};
		var object = {};
		for (var i = 0; i < arguments.length; i++) {
			var argument = arguments[i];
			for (var property in argument) {
				object[property] = argument[property];
			}
		};
		return object;
	};

	var csvToArray = function (csv, delimiter, ignoreEmptyValues) {
		if (!csv)
			return [];
		if (!delimiter)
			delimiter = ',';
		if (!ignoreEmptyValues)
			ignoreEmptyValues = false;
		var values = csv.split(delimiter);
		var valueArray = [];
		for (var i = 0; i < values; i++)
		{
			var value = values[i];
			if (ignoreEmptyValues && (value.trim() == ''))
				continue;
			valueArray.push(value);
		}
		return valueArray;
	};

	var csvToIntegerArray = function (csv, delimiter) {
		if (!csv)
			return [];
		if (!delimiter)
			delimiter = ',';
		var valueArray = csv.split(delimiter);
		var integerArray = [];
		for (var i = 0; i < valueArray.length; i++) {
			var value = parseInt(valueArray[i]);
			if(!isNaN(value))
				integerArray.push(value);
		}
		return integerArray;
	};

	var csvToFloatArray = function (csv, delimiter) {
		if (!csv)
			return [];
		if (!delimiter)
			delimiter = ',';
		var valueArray = csv.split(delimiter);
		var floatArray = [];
		for (var i = 0; i < valueArray.length; i++) {
			var value = parseFloat(valueArray[i]);
			if (!isNaN(value))
				floatArray.push(value);
		}
		return floatArray;
	};

	var arrayToCSV = function (valueArray, delimiter) {
		if (!valueArray)
			return '';
		if (!delimiter)
			delimiter = ',';
		var csv = '';
		for (var i = 0; i < valueArray.length; i++) {
			if (i > 0)
				csv += delimiter;
			csv += valueArray[i];
		}
		return csv;
    };


    var stringToCSV = function (string, delimiter) {
        if (!string)
            return '';
        if (!delimiter)
            delimiter = ',';

        var charArray = string.split('');
        var csvString = '';
        for (var i = 0; i < charArray.length; i++)
        {
            csvString += charArray[i];
            if (i != charArray.length - 1)
                csvString += delimiter;
        }
        return csvString;
    }


	var arrayIntersect = function (arrayOne, arrayTwo) {
		var intersection = [];
		for (var i = 0; i < arrayOne.length; i++) {
			for (var j = 0; j < arrayTwo.length; j++) {
				if (arrayOne[i] === arrayTwo[j])
					intersection.push(arrayOne[i]);
			}
		}
		return intersection;
	};

	var removeDuplicatesFromArray = function (array) {
		var dedupedArray = [];
		for (var i = 0; i < array.length; i++) {
			var value = array[i];
			if (dedupedArray.indexOf(value) == -1)
				dedupedArray.push(value);
		}
		return dedupedArray;
	};

	var parseIntOrZero = function (value, defaultValue) {
		if (!defaultValue)
			defaultValue = 0;
		var numberValue = parseInt(value);
		if (isNaN(numberValue))
			return defaultValue;
		return numberValue
	};

	var parseFloatOrZero = function (value, defaultValue) {
		if (!defaultValue)
			defaultValue = 0;
		var numberValue = parseFloat(value);
		if (isNaN(numberValue))
			return defaultValue;
		return numberValue
	};

	return {
		boundedNumber: boundedNumber,
		right: right,
		left: left,
		extend: extend,
		csvToArray: csvToArray,
		csvToIntegerArray: csvToIntegerArray,
		csvToFloatArray: csvToFloatArray,
		arrayToCSV: arrayToCSV,
		arrayIntersect: arrayIntersect,
		parseIntOrZero: parseIntOrZero,
        parseFloatOrZero: parseFloatOrZero,
        stringToCSV: stringToCSV,
	};
});
define('uuid', [], function () {
	'use strict';

	var generateUUID = function () {
		var d = new Date().getTime();
		var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
			var r = (d + Math.random() * 16) % 16 | 0;
			d = Math.floor(d / 16);
			return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
		});
		return uuid;
	};

	return {
		generateUUID: generateUUID
	};
});
define('window', [], function () {
	'use strict';

	var redirect = function (url) {
		window.location = url;
	};

	return {
		localStorage: window.localStorage,
		redirect: redirect,
		parent: window.parent
	};
});
define('windowProgressBar', ['jquery', 'utils'], function ($, utils) {

	var $container = null;
	var $bar = null;
	var removingTimer = -1;

	// percent between 0 - 100
	var setProgress = function (percent) {
		percent = utils.boundedNumber(percent, 1, 100);
		clearTimeout(removingTimer);
		if ($bar == null) {
			$container = $('<div>').addClass('window-progress-bar-container');
			$bar = $('<div>').addClass('window-progress-bar');
			$container.append($bar);
			$('body').prepend($container);
		}	
		else if ($container.hasClass('removing'))
			$container.removeClass('removing');
		$bar.css('width', percent + '%');
	};

	var removeProgressBar = function () {
		if ($container == null)
			return;
		$container.addClass('removing');
		removingTimer = setTimeout(function () {
			$container.remove();
			$container = null;
			$bar = null;
		}, 500);
	};

	return {
		setProgress: setProgress,
		removeProgressBar: removeProgressBar
	};
});